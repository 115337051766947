import React from 'react'
import { graphql } from 'gatsby'
import BannerTitleOnlyBlock from 'components/blocks/BannerTitleOnlyBlock'
import SlideshowTimelineBlock from 'components/blocks/SlideshowTimelineBlock'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'

export default function TimelinePage({ data }) {
  const { locale } = useLocalization()
  const page = data.strapiTimelinePage
  const { timelineItems, title } = page
  const bannerCustom = {
    // pretitle: tr('about', locale),
    title,
  }
  return (
    <div className='page-timeline'>
      <BannerTitleOnlyBlock content={bannerCustom} />
      <SlideshowTimelineBlock content={timelineItems} />
    </div>
  )
}

export const pageQuery = graphql`
  query ($locale: String!) {
    strapiTimelinePage(locale: { eq: $locale }) {
      title
      seo {
        title
        description
      }
      timelineItems {
        year
        title
        body
        image1 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image2 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
