import React, { useState } from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import MarkdownWrap from 'components/MarkdownWrap'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  Navigation,
  Autoplay,
  EffectFade,
  Parallax,
  Pagination,
} from 'swiper'

SwiperCore.use([EffectFade])
SwiperCore.use([Autoplay])

export default function SlideshowTimelineBlock({ content }) {
  const [activeIndex, setActiveIndex] = useState(false)
  SwiperCore.use([Navigation, Autoplay, EffectFade, Parallax, Pagination])
  return (
    <section className='slideshow-timeline-block pad-v-standard'>
      <Inview className='inner'>
        <Timeline items={content} activeIndex={activeIndex} />
        <Slides items={content} setActiveIndex={setActiveIndex} />
      </Inview>
    </section>
  )
}

const Timeline = ({ items, activeIndex }) => {
  const ratio = activeIndex / (items.length - 1)
  return (
    <div className='timeline container'>
      <div className='block left'>
        <div className='line' />
      </div>
      <div className='inner'>
        <div className='progress-bar'>
          <div className='inner'>
            <div className='off' />
            <div className='on' style={{ transform: `scaleX(${ratio})` }} />
          </div>
        </div>
        <div className='swiper-pagination' />
      </div>
      <div className='block right'>
        <div className='line' />
      </div>
    </div>
  )
}

const Slides = ({ items, setActiveIndex }) => {
  return (
    <div className='slides'>
      <div className='inner'>
        <div className='nav container'>
          <div className='inner'>
            <div className='swiper-button swiper-button-prev'>
              <div className='arrow-wrap'>
                <div className='arrow arrow-bar is-left' />
              </div>
            </div>
            <div className='swiper-button swiper-button-next'>
              <div className='arrow-wrap'>
                <div className='arrow arrow-bar is-right' />
              </div>
            </div>
          </div>
        </div>

        <Swiper
          // effect='fade'
          autoplay={{
            delay: 6000,
            // disableOnInteraction: true,
          }}
          speed={800}
          onSwiper={(swiper) => setActiveIndex(swiper.activeIndex)}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          slidesPerView={1}
          parallax={true}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          pagination={{
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index) {
              return `<div class="swiper-pagination-bullet timeline-item">
            <h4 class="year">${items[index].year}</h4>
            <div class="bar"></div>
            </div>`
            },
          }}
        >
          {items.map((item, i) => (
            <SwiperSlide key={i}>
              <Slide content={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

const Slide = ({ content }) => {
  const { year, title, body, image1, image2 } = content
  return (
    <>
      <div className='text'>
        {year && (
          <div data-swiper-parallax='100%'>
            <TextWrap>
              <h1 className='larger'>{year}</h1>
            </TextWrap>
          </div>
        )}
        {title && (
          <div data-swiper-parallax='40%'>
            <TextWrap>
              <h2>{title}</h2>
            </TextWrap>
          </div>
        )}
        {body && (
          <div data-swiper-parallax='60%'>
            <TextWrap>
              <div className='border' />
              <div className='body'>
                <MarkdownWrap body={body} />
              </div>
            </TextWrap>
          </div>
        )}
      </div>
      <div className='images'>
        <div data-swiper-parallax='80%'>
          <div className='container'>
            {image1 && (
              <div className='image'>
                <div className='inner'>
                  <ImageWrap image={image1} />
                </div>
              </div>
            )}
            {image2 && (
              <div className='image'>
                <div className='inner'>
                  <ImageWrap image={image2} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const TextWrap = ({ children }) => {
  return <div className='text-wrap container'>{children}</div>
}
